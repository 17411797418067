import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useResolvers = () => {
  const { t } = useTranslation();

  const QuestionTypeResolver = useMemo(
    () => ({
      1: t("questionTypes.mcq"),
      2: t("questionTypes.descriptive"),
      3: t("questionTypes.pictorial"),
      4: t("questionTypes.whiteBoard"),
    }),
    [t]
  );

  const QuestionTypes = useMemo(
    () => ({
      [t("questionTypes.mcq")]: 1,
      [t("questionTypes.descriptive")]: 2,
      [t("questionTypes.pictorial")]: 3,
      [t("questionTypes.whiteBoard")]: 4,
    }),
    [t]
  );

  const QuestionBankResolver = useMemo(
    () => ({
      1: t("questionBankTypes.assessment"),
      2: t("questionBankTypes.test"),
    }),
    [t]
  );

  const QuestionBanks = useMemo(
    () => ({
      [t("questionBankTypes.assessment")]: 1,
      [t("questionBankTypes.test")]: 2,
    }),
    [t]
  );

  const QuestionDifficultyResolver = useMemo(
    () => ({
      1: t("questionDifficulties.easy"),
      2: t("questionDifficulties.medium"),
      3: t("questionDifficulties.hard"),
      4: t("questionDifficulties.hardest"),
      5: t("questionDifficulties.inferno"),
    }),
    [t]
  );

  const DifficultyScale = useMemo(
    () => ({
      10: 1,
      30: 2,
      50: 3,
      70: 4,
      90: 5,
    }),
    []
  );

  const AssessmentStatus = useMemo(
    () => ({
      [t("assessmentStatuses.drafted")]: 1,
      [t("assessmentStatuses.published")]: 2,
      [t("assessmentStatuses.submitted")]: 3,
      [t("assessmentStatuses.checked")]: 4,
      [t("assessmentStatuses.graded")]: 5,
      [t("assessmentStatuses.pending")]: 6,
      [t("assessmentStatuses.inProgress")]: 7,
    }),
    [t]
  );

  const AssessmentStatusResolver = useMemo(
    () => ({
      1: t("assessmentStatuses.drafted"),
      2: t("assessmentStatuses.published"),
      3: t("assessmentStatuses.submitted"),
      4: t("assessmentStatuses.checked"),
      5: t("assessmentStatuses.graded"),
      6: t("assessmentStatuses.pending"),
      7: t("assessmentStatuses.inProgress"),
    }),
    [t]
  );

  const AssessmentTypes = useMemo(
    () => ({
      [t("assessmentTypes.assignment")]: 1,
      [t("assessmentTypes.exam")]: 2,
      [t("assessmentTypes.quiz")]: 3,
    }),
    [t]
  );

  const AssessmentTypesResolver = useMemo(
    () => ({
      1: t("assessmentTypes.assignment"),
      2: t("assessmentTypes.exam"),
      3: t("assessmentTypes.quiz"),
    }),
    [t]
  );

  const AssessmentDifficulty = useMemo(
    () => ({
      [t("questionDifficulties.easy")]: 1,
      [t("questionDifficulties.medium")]: 2,
      [t("questionDifficulties.hard")]: 3,
      [t("questionDifficulties.hardest")]: 4,
      [t("questionDifficulties.inferno")]: 5,
    }),
    [t]
  );

  const SliderDifficultyLevels = useMemo(
    () => [
      { value: 10, label: t("questionDifficulties.easy") },
      { value: 30, label: t("questionDifficulties.medium") },
      { value: 50, label: t("questionDifficulties.hard") },
      { value: 70, label: t("questionDifficulties.hardest") },
      { value: 90, label: t("questionDifficulties.inferno") },
    ],
    [t]
  );

  const LectureTypes = useMemo(
    () => ({
      [t("lectureTypes.video")]: 1,
      [t("lectureTypes.audio")]: 2,
      [t("lectureTypes.theory")]: 3,
    }),
    [t]
  );

  const LectureTypesResolver = useMemo(
    () => ({
      1: t("lectureTypes.video"),
      2: t("lectureTypes.audio"),
      3: t("lectureTypes.theory"),
    }),
    [t]
  );

  return {
    QuestionTypeResolver,
    QuestionTypes,
    QuestionBankResolver,
    QuestionBanks,
    QuestionDifficultyResolver,
    DifficultyScale,
    AssessmentStatus,
    AssessmentStatusResolver,
    AssessmentTypes,
    AssessmentTypesResolver,
    AssessmentDifficulty,
    SliderDifficultyLevels,
    LectureTypes,
    LectureTypesResolver,
  };
};

export default useResolvers;
