import * as React from "react";
import { useTranslation } from "react-i18next";

import { Generic } from "src/@types/Category";

import { CATEGORY } from "src/components/Dropzone";

import { PROTECTED_ROUTES } from "src/constants/Navigation";

import useCourse from "src/hooks/useCourse";
import useRoutes from "src/hooks/useRoutes";

import { trackAction } from "src/helpers/tracking";
import agent, { token, API_ROOT } from "src/helpers/apiAgent";
import { errorHandler, successHandler } from "src/helpers/responseHandler";

// Set defaults for reuse
const DEFAULTS = {
  loaders: {
    lecture: false,
    file: false,
  },
  lectureData: {
    name: "",
    description: "",
    tagSlots: [],
    type: CATEGORY.Video,
    transcription: "",
    link: "",
    content: "",
  },
  updateId: "",
  fileName: "",
  removeFile: () => {},
  resetLectureData: () => {},
  addLectureTags: (val: string) => {},
  handleFileUpload: (e: Generic) => {},
  setLectureData: (data: Generic) => {},
  removeLectureTags: (val: string) => {},
  onSubmitLecture: async (courseId: string) => false,
  editLecture: (data: Generic, subject: Generic) => {},
};

const LectureContext = React.createContext(DEFAULTS);

const LectureProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { setRefetch } = useCourse();
  const { navigateTo } = useRoutes();

  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [fileName, setFileName] = React.useState(DEFAULTS.fileName);
  const [updateId, setUpdateId] = React.useState(DEFAULTS?.updateId);
  const [lectureData, setLectureData] = React.useState(DEFAULTS.lectureData);

  const lectureValidate = () => {
    if (!lectureData?.name) {
      errorHandler(t("errors.lectureTitle"));
      return false;
    }
    if (!lectureData?.type) {
      errorHandler(t("errors.lectureType"));
      return false;
    }
    if (lectureData?.type === 3) {
      if (!lectureData?.content && !lectureData?.link) {
        errorHandler(t("errors.lectureMaterial"));
        return false;
      }
      if (
        lectureData?.link &&
        !lectureData?.content &&
        !lectureData?.description
      ) {
        errorHandler(t("errors.lectureDescription"));
        return false;
      }
    } else {
      if (!lectureData?.description) {
        errorHandler(t("errors.lectureDescription"));
        return false;
      }
    }
    if (lectureData?.tagSlots?.length === 0) {
      errorHandler(t("errors.lectureAITags"));
      return false;
    }
    return true;
  };

  const resetLectureData = () => {
    setLectureData(DEFAULTS?.lectureData);
    setUpdateId(DEFAULTS?.updateId);
    setFileName(DEFAULTS?.fileName);
  };

  const addLectureTags = (val: string) => {
    const index = lectureData?.tagSlots?.findIndex((t) => t?.tagId === val);
    if (index > -1) {
      const updated = [...lectureData?.tagSlots];
      updated.splice(index, 1);
      setLectureData((prev) => ({ ...prev, tagSlots: updated }));
    } else {
      setLectureData((prev) => ({
        ...prev,
        tagSlots: [
          ...lectureData?.tagSlots,
          { startTime: "00:00", endTime: "00:00", tagId: val },
        ],
      }));
    }
  };

  const removeLectureTags = (val: string) => {
    const index = lectureData?.tagSlots?.findIndex((t) => t?.tagId === val);
    if (index > -1) {
      const updated = [...lectureData?.tagSlots];
      updated.splice(index, 1);
      setLectureData((prev) => ({ ...prev, tagSlots: updated }));
    }
  };

  const editLecture = (data, subject) => {
    const extractedData = { ...DEFAULTS.lectureData };
    Object.keys(DEFAULTS?.lectureData).forEach(
      (key) => (extractedData[key] = data[key] ?? DEFAULTS.lectureData[key])
    );
    setLectureData(extractedData);
    setUpdateId(data?.id);
    if (extractedData?.link) {
      const fileName = extractedData?.link?.split("/");
      setFileName(fileName[fileName?.length - 1]?.replaceAll("_", " "));
    }
    navigateTo(PROTECTED_ROUTES.COURSE_TOPIC_ADD_LECTURE, {
      state: { subject },
    });
  };

  const onSubmitLecture = async (courseId: string) => {
    // Making decision to update or create question
    let response = false;
    if (updateId) {
      response = await handleUpdateLecture();
    } else if (!updateId) {
      response = await handleSubmitLecture(courseId);
    }
    setRefetch((prev) => !prev);
    return response;
  };

  const handleUpdateLecture = async () => {
    try {
      // checking data validity
      if (lectureValidate()) {
        setLoaders((prev) => ({ ...prev, lecture: true }));
        const response = await agent.Course.updateLecture(
          updateId,
          lectureData
        );

        if (response) {
          trackAction("Lecture_Update", {
            lectureId: updateId,
            lecture: lectureData?.name,
          });
          successHandler("Lecture updated!");

          setLectureData(DEFAULTS?.lectureData);
          setRefetch((prev) => !prev);
          return true;
        }
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoaders((prev) => ({ ...prev, lecture: false }));
    }
  };

  const handleSubmitLecture = async (courseId: string) => {
    try {
      // validate data
      if (lectureValidate()) {
        setLoaders((prev) => ({ ...prev, lecture: true }));

        const response = await agent.Course.createLecture(lectureData);

        if (response) {
          trackAction("Lecture_Create", {
            course: courseId,
            lecture: lectureData?.name,
          });
          successHandler("Lecture created!");

          const courseResponse = await agent.Course.addLectureInCourse(
            response?.data?.id,
            courseId
          );
          if (courseResponse) {
            setFileName(DEFAULTS.fileName);
            setLectureData(DEFAULTS?.lectureData);
          }
          setRefetch((prev) => !prev);
          return true;
        }
        return false;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoaders((prev) => ({ ...prev, lecture: false }));
    }
  };

  const handleFileUpload = async (file) => {
    try {
      if (file?.length) {
        const payload = {
          fileName: file?.[0]?.name,
          fileType: file?.[0]?.type,
        };

        setLoaders((prev) => ({ ...prev, file: true }));
        let response = await fetch(`${API_ROOT}/upload/getSignedUrl`, {
          method: "post",
          body: JSON.stringify(payload),
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        response = await response?.json();

        const response2 = await fetch(`${response?.url}`, {
          method: "put",
          body: file?.[0],
          headers: {
            "Content-Type": `${file?.[0]?.type}`,
          },
        });

        if ([200, 201].includes(response2?.status)) {
          setLectureData((prev) => ({
            ...prev,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            link: response?.fileUrl ?? "",
          }));
          setFileName(file[0].name);
        } else {
          errorHandler(t("errors.failedToUpload"));
        }
      }
    } catch (error) {
      errorHandler(error);
      return false;
    } finally {
      setLoaders((prev) => ({ ...prev, file: false }));
    }
  };

  const removeFile = () => {
    setLectureData((prev) => ({ ...prev, link: "" }));
    setFileName(DEFAULTS.fileName);
  };

  const contextValues = {
    loaders,
    fileName,
    updateId,
    removeFile,
    lectureData,
    editLecture,
    setLectureData,
    addLectureTags,
    onSubmitLecture,
    handleFileUpload,
    resetLectureData,
    removeLectureTags,
  };

  return (
    <LectureContext.Provider value={contextValues}>
      {children}
    </LectureContext.Provider>
  );
};

export { LectureContext };
export default LectureProvider;
