import ReactGA from "react-ga4";
import Clarity from "@microsoft/clarity";

export const trackAction = (eventName: string, eventParams?: object) => {
  Clarity.event(eventName);
  ReactGA.event(eventName, eventParams);
};

export const userTracking = ({ userId, userName, school, role }) => {
  Clarity.identify(userId, undefined, undefined, userName);
  Clarity.setTag(`${role}-name`, userName);
  Clarity.setTag("school", school);
  Clarity.setTag("role", role);

  ReactGA.set({
    user_id: userId,
    user_name: userName,
    user_role: role,
    school: school,
  });
};
