import uniqBy from "lodash/uniqBy";

import { errorHandler, successHandler } from "./responseHandler";

export const flatObject = (array) => {
  let result = [];
  array.forEach((entity) => {
    result.push(entity);
    if (Array.isArray(entity?.children)) {
      result = result.concat(flatObject(entity?.children));
    }
  });
  return result;
};

export const tryCatchProtectedCall = async (
  apiCall,
  callbacks,
  errorCallbacks,
  loader
) => {
  try {
    if (loader) {
      loader(true);
    }
    const response = await apiCall();
    if (callbacks && [200, 201, 202]?.includes(response?.code)) {
      callbacks();
      successHandler(response);
    } else if (errorCallbacks) {
      errorCallbacks();
    }
  } catch (e) {
    errorHandler(e);
  } finally {
    if (loader) {
      loader(false);
    }
  }
};

export const parentSearchReccurssion = (results, allCategories) => {
  if (results?.length) {
    const parents = [];
    results?.forEach((res) => {
      const pars = allCategories?.filter((tag) => tag?.id === res?.parent);
      parents.push(res, ...pars);
    });
    return parents;
  }
  return [];
};

export const categorySearcher = (searchQuery, setter, allCategories) => {
  const results = allCategories?.filter((tag) =>
    tag?.text?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const level4 = parentSearchReccurssion(results, allCategories);
  const level3 = parentSearchReccurssion(level4, allCategories);
  const level2 = parentSearchReccurssion(level3, allCategories);
  const level1 = parentSearchReccurssion(level2, allCategories);

  let hierarchy = [...level1, ...level2, ...level3, ...level4];
  hierarchy = uniqBy(hierarchy, "id");

  setter(hierarchy);

  // Reset to actual categories
  if (searchQuery?.length === 0) {
    setter(allCategories);
  }
};

export const getFileType = (lectureUrl) => {
  const extension = lectureUrl.split(".").pop();

  switch (extension) {
    case "mp4":
    case "avi":
    case "mov":
      return "video";
    case "mp3":
    case "wav":
      return "audio";
    case "doc":
    case "docx":
    case "pdf":
    case "ppt":
    case "pptx":
    case "xls":
    case "xlsx":
      return "document";
    default:
      return "unknown";
  }
};

export const isValueNegative = (value) => Math.sign(value) === -1;

export const indicateNumberSign = (value) => {
  if (value && isValueNegative(value)) {
    value = value?.replace("-", "");
    return `(${value}%)`;
  }
  return `${value || 0}%`;
};

export const containsHTML = (str: string) => {
  const htmlRegex = /<[^>]*>/g;
  return htmlRegex.test(str);
};

export const toLetters = (num: number): string => {
  const mod = num % 26;
  let pow = (num / 26) | 0;
  const out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z");
  return pow ? toLetters(pow) + out : out;
};

export const isURL = (str: string | number) => {
  try {
    if (typeof str !== "string") {
      return false;
    }

    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const getFileName = (url: string) => {
  try {
    return url.substring(url.lastIndexOf("/") + 1);
  } catch (error) {
    console.error("Invalid URL:", error);
    return "-";
  }
};
